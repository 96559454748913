#sidebar-content {
    padding-left: 0;
    position: relative;
}
#sidebar-content #sidebar {
    /* min-height: 100vh; */
    position: fixed;
    top: 10vh;
    left: 0;
    width: 22%;
    height: 90vh;
}
.sidebar-link {
    color: #DCDCDC;
    padding-top: 15px;
    padding-bottom: 15px;
}
.text-icon {
    color: #237CC0;
}
.sidebar-link.active, .sidebar-link:focus {
    background: #237CC0;
    color: #fff !important;
    text-decoration: none;
}

/* 

@media (max-width: 1199.98px) {
    #sidebar-content #sidebar {
        height: 100%;
        width: 100%;
        margin-left: 0;
    }

    #sidebar-content #sidebar .sidebar-link {
        display: flex;
        align-items: center;
        transition: background 0.3s;
        padding: 0.5rem 1.5rem;
    }

    #sidebar-content #sidebar .sidebar-link span {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        display: inline-block !important;
        font-size: 14.4px;
    }

    .sidebar .sidebar-link i {
        margin-right: 1rem !important;
    }
} */